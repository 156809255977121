import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { Badge } from 'antd';
import useBreakpoints from '@hooks/useBreakpoints';
import { AvatarTuringo } from '@components/avatar/Avatar';
import Style from './styles/ChatListItem.scss';
import { useHistory, useLocation, useParams } from 'react-router';
import { Router } from '@Router';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import dayjs from 'dayjs';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Link } from 'react-router-dom';
import { markChatAsRead } from '@state/reducers/Notifications/NotificationReducer';
import { Check, CloseOne } from '@icon-park/react';
import { SocketContext } from 'src/SocketComponent';
import { SilenceIcon } from '@components/icons/SilenceIcon';
import strings from '@resources/localization';
import { MoreButton } from '@components/buttons/more_button/MoreButton';
const ChatListItem = (props) => {
    var _a, _b, _c;
    const { cm_pk } = useParams();
    const { item, state = 0, style = {}, onClose, showOptions } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const isTablet = breakpoints.isTablet;
    const isSmall = breakpoints.isSmall;
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const location = useLocation();
    const roomSelected = (_a = location.state) === null || _a === void 0 ? void 0 : _a.roomPk;
    const history = useHistory();
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const language = useAppSelector((state) => state.ui.language);
    const onClick = () => {
        history.push(Router.Path.community_chat({ cm_pk: cm_pk || currentCommunity }), { roomPk: item.publicKey });
        if (onClose)
            onClose();
    };
    const dispatch = useAppDispatch();
    const participants = (_b = item === null || item === void 0 ? void 0 : item.users) === null || _b === void 0 ? void 0 : _b.filter((u) => {
        return u.publicKey != currentEntity.publicKey;
    });
    const hasNotificationSilence = !!((_c = item === null || item === void 0 ? void 0 : item.users) === null || _c === void 0 ? void 0 : _c.find((u) => {
        if (u.publicKey == currentEntity.publicKey && u.roomNotifications == false) {
            return true;
        }
        return false;
    }));
    const socket = useContext(SocketContext);
    const [isTyping, SetIsTyping] = useState(false);
    const [timer, setTimer] = useState();
    useEffect(() => {
        var _a, _b, _c, _d;
        if (((_b = (_a = socket.lastJsonMessage) === null || _a === void 0 ? void 0 : _a.channel) === null || _b === void 0 ? void 0 : _b.id) == 'chat.ctl') {
            const notification = socket.lastJsonMessage;
            if (((_c = notification === null || notification === void 0 ? void 0 : notification.body) === null || _c === void 0 ? void 0 : _c.roomPk) == item.publicKey && ((_d = notification === null || notification === void 0 ? void 0 : notification.body) === null || _d === void 0 ? void 0 : _d.action) == 'input.change') {
                SetIsTyping(true);
                if (timer) {
                    clearTimeout(timer);
                }
                const timerRef = setTimeout(() => SetIsTyping(false), 1000);
                setTimer(timerRef);
            }
        }
    }, [socket.lastJsonMessage]);
    const items = [
        {
            label: strings.screens.chat.notification.options.markAsRead,
            key: '1',
            onClick: (e) => {
                e.domEvent.stopPropagation();
                const message = {
                    channel: 'chat.ctl',
                    body: {
                        action: 'readability.read',
                        communityPk: cm_pk || currentCommunity,
                        roomPk: item.publicKey,
                        userPk: currentEntity.publicKey,
                    },
                };
                dispatch(markChatAsRead({ cm_pk: cm_pk || currentCommunity, r_pk: item.publicKey }));
                socket.sendMessage(JSON.stringify(message));
            },
            icon: _jsx(Check, { style: { display: 'flex', justifyContent: 'center' } }),
        },
    ];
    if (!item)
        return _jsx(_Fragment, {});
    return (_jsxs(Link, { to: { pathname: Router.Path.community_chat({ cm_pk: cm_pk || currentCommunity }), state: { roomPk: item.publicKey } }, onClick: onClick, className: roomSelected == item.publicKey ? Style.itemActive : Style.item, style: Object.assign({ display: 'flex', alignItems: 'center', padding: isMobile ? 16 : '16px 24px' }, style), children: [_jsx(AvatarTuringo, { size: 16, style: {
                    height: 40,
                    width: 40,
                    lineHeight: '40px',
                    marginRight: 16,
                }, name: participants && participants.length == 1 ? participants[0].name : item === null || item === void 0 ? void 0 : item.title, avatar: participants && participants.length == 1 && participants[0].avatarUrl }), _jsx("div", { style: { lineHeight: '16px', flexGrow: 1 }, children: _jsxs("div", { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        maxWidth: isSmall
                            ? state > 0
                                ? 'calc(100vw - 128px)'
                                : 'calc(100vw - 104px)'
                            : isTablet
                                ? state > 0
                                    ? 'calc(100vw - 164px)'
                                    : 'calc(100vw - 136px)'
                                : state > 0
                                    ? 159
                                    : 200,
                    }, children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(Paragraph, { className: 'turingo-text bold', style: { marginBottom: 0, wordBreak: 'break-word', marginRight: 4 }, ellipsis: { rows: 1 }, children: participants && (participants.length == 1 ? participants[0].name : item.title) }), hasNotificationSilence && _jsx(SilenceIcon, { color: "var(--neutral-8)" })] }), item.messageLast && !isTyping && (_jsxs("div", { style: { display: 'flex' }, children: [item.messageLast.isDeleted && (_jsx(CloseOne, { style: { display: 'flex', alignItems: 'center', marginRight: 8 }, fill: item.messageLast.isDeleted ? 'var(--neutral-7)' : 'var(--neutral-13)' })), _jsx(Paragraph, { className: 'turingo-text', style: {
                                        marginBottom: 0,
                                        wordBreak: 'break-word',
                                        color: item.messageLast.isDeleted ? 'var(--neutral-7)' : 'var(--neutral-13)',
                                    }, ellipsis: { rows: 1 }, children: item.messageLast.isDeleted
                                        ? strings.screens.chat.messages.deleteMessage
                                        : `${item.messageLast.userPk == currentEntity.publicKey ? 'Tú: ' : ''}${item.messageLast.content}` })] })), isTyping && (_jsx(Paragraph, { className: 'turingo-text', style: { marginBottom: 0, wordBreak: 'break-word', color: 'var(--ant-primary-color)' }, ellipsis: { rows: 1 }, children: strings.screens.chat.messages.isTyping.withoutName })), item.messageLast && _jsx("div", { className: 'turingo-secondary', children: dayjs(item.messageLast.dateSent).locale(language).fromNow() })] }) }), state > 0 && _jsx(Badge, { count: state, style: { margin: 16 } }), showOptions && state > 0 && _jsx(MoreButton, { size: 'small', type: "text", items: items })] }));
};
export { ChatListItem };
