import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import Meta from 'react-meta-tags';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Card, Tabs } from 'antd';
import { boardDetails, postList } from '@state/actions';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import useBreakpoints from '@hooks/useBreakpoints';
import { checkBlockedScroll } from '@hooks/useBlockScroll';
import strings from '@resources/localization';
import { hasError } from '@util/StateHandler';
import { Colors } from '@util/color/Colors';
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout';
import { Header } from '../../components/Header/Header';
import { PostSkeleton } from '@components/skeletons/components/PostSkeleton/PostSkeleton';
import { ListPost } from '@components/content/posts/list_post/ListPosts';
import { CreatePost } from '@components/content/posts/creation_box/CreationBox';
import { CommunityRules } from '../../components/community_rules/CommunityRules';
import { CommunityScreenSkeleton } from '@components/skeletons/CommunityScreenSkeleton';
import { AlertDashed } from '@components/alerts/alert_dashed/AlertDashed';
import { CompleteProfile } from '@components/content/entity/complete_profile/CompleteProfile';
import { MembersCard } from '../../components/members_card/MembersCard';
import { AllMembersCard } from '../../components/members_card/AllMembersCard';
import { Router } from '@Router';
import { CommunityAbout } from '../../components/community_about/CommunityAbout';
import { PostModal } from '@components/content/posts/post_modal/PostCreateProvider';
import { dynamicTabName } from '@util/string/Functions';
import { NotificationContext } from 'src/SocketComponent';
import Paragraph from 'antd/lib/typography/Paragraph';
import { LinkButton } from '@components/buttons/link_button/LinkButton';
import { PushpinOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;
export const DiscussionDetail = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    const dispatch = useAppDispatch();
    const { cm_pk, b_pk } = useParams();
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const posts = useAppSelector((state) => { var _a; return (_a = state.posts.all[b_pk]) === null || _a === void 0 ? void 0 : _a.items; });
    const postState = useAppSelector((state) => { var _a; return (_a = state.posts.all[b_pk]) === null || _a === void 0 ? void 0 : _a.listState; });
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const notifications = useContext(NotificationContext);
    const [postType, setPostType] = useState('basic');
    const [postModal, setPostModal] = useState(false);
    const [postStep, setPostStep] = useState('creation');
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const isSmall = breakpoints.isSmall;
    useEffect(() => {
        void dispatch(boardDetails({ urlParams: { cm_pk, b_pk } }));
        dispatchPosts();
    }, [b_pk]);
    const dispatchPosts = () => {
        void dispatch(postList({
            urlParams: { cm_pk, b_pk },
            headerParams: {
                page: 'first',
            },
        }));
    };
    const hasPostToRead = ((_b = (_a = community === null || community === void 0 ? void 0 : community.item) === null || _a === void 0 ? void 0 : _a.pinneds) === null || _b === void 0 ? void 0 : _b.readMandatory) - ((_d = (_c = community === null || community === void 0 ? void 0 : community.item) === null || _c === void 0 ? void 0 : _c.pinneds) === null || _d === void 0 ? void 0 : _d.read);
    if (!community || !community.item || !((_f = (_e = community.boards) === null || _e === void 0 ? void 0 : _e.detail) === null || _f === void 0 ? void 0 : _f[b_pk]) || ((_h = (_g = community.boards) === null || _g === void 0 ? void 0 : _g.detail) === null || _h === void 0 ? void 0 : _h[b_pk].state.loading) || !postState) {
        return _jsx(CommunityScreenSkeleton, {});
    }
    else if (hasError([community.state, posts])) {
        return _jsx(CommunityScreenSkeleton, { error: true });
    }
    else {
        const firstScreen = (_jsxs("div", { style: { display: 'grid', rowGap: isMobile ? 16 : 24, position: 'relative' }, children: [_jsx(Header, { filter: true }), isLogged && _jsx(CreatePost, { setPostModal: setPostModal, setPostStep: setPostStep, setPostType: setPostType }), hasPostToRead > 0 && (_jsx(Card, { bordered: false, children: _jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }, children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center', marginBottom: isMobile ? 16 : 0 }, children: [_jsx("div", { style: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '100px',
                                            backgroundColor: 'var(--ant-primary-1)',
                                            height: 40,
                                            width: 40,
                                        }, children: _jsx(PushpinOutlined, { style: { fontSize: 24, color: 'var(--ant-primary-color)' } }) }), _jsxs(Paragraph, { strong: true, style: { marginLeft: 8, marginRight: 8 }, children: [hasPostToRead == 1 && strings.screens.markasread.banner.singular, ' ', hasPostToRead > 1 && strings.formatString(strings.screens.markasread.banner.plural, hasPostToRead)] })] }), _jsx(LinkButton, { block: true, type: "primary", href: Router.Path.community_highlights({ cm_pk }), children: strings.screens.markasread.banner.action })] }) })), _jsx(InfiniteScroll, { dataLength: Object.keys(posts).length, next: () => {
                        if (!checkBlockedScroll()) {
                            if (!isLogged) {
                                dispatch(modalAction({ modal: 'see_more', state: true }));
                            }
                            else {
                                void dispatch(postList(Object.assign({ urlParams: {
                                        cm_pk,
                                        b_pk,
                                    }, headerParams: {
                                        page: postState === null || postState === void 0 ? void 0 : postState.pagination,
                                    } }, ((postState === null || postState === void 0 ? void 0 : postState.topic) && {
                                    searchParams: {
                                        topic: postState === null || postState === void 0 ? void 0 : postState.topic,
                                    },
                                }))));
                            }
                        }
                    }, hasMore: (postState.pagination && postState.pagination != 'first') || postState.loading, loader: !isLogged ? null : _jsx(PostSkeleton, {}), endMessage: _jsx(Card, { style: { marginTop: 16 }, children: _jsx(AlertDashed, { showIcon: false, message: strings.screens.boards.discussion.details.no_more }) }), children: _jsx(ListPost, { items: posts }) })] }));
        const isCompleteProfile = (currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.avatarUrl) && ((_j = currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.profile) === null || _j === void 0 ? void 0 : _j.job);
        return (_jsxs(_Fragment, { children: [_jsx(Meta, { children: _jsx("title", { children: dynamicTabName((_l = (_k = community === null || community === void 0 ? void 0 : community.boards.detail[b_pk]) === null || _k === void 0 ? void 0 : _k.item) === null || _l === void 0 ? void 0 : _l.name, notifications) }) }), postModal && (_jsx(PostModal, { item: {
                        kind: postType,
                        boardPk: b_pk,
                    }, initialScreen: postStep, open: postModal, setVisible: setPostModal })), isMobile && (_jsxs("div", { children: [isLogged && !isCompleteProfile && _jsx(CompleteProfile, {}), _jsxs(Tabs, { className: "with-margin", tabBarStyle: {
                                padding: breakpoints.isSmall ? '0 16px' : '0 24px',
                                background: Colors('BACKGROUND'),
                                marginBottom: 0,
                                borderRadius: breakpoints.isSmall ? 0 : '4px 4px 0px 0px',
                            }, defaultActiveKey: "1", children: [_jsx(TabPane, { tab: strings.general.discussion.singular, children: firstScreen }, "1"), cm_pk != 'turingo' && (_jsx(TabPane, { tab: strings.screens.boards.discussion.subscribers, children: _jsxs("div", { style: { display: 'grid', rowGap: isMobile ? 16 : 24 }, children: [_jsx(Header, {}), _jsx(Card, { title: strings.screens.community.members.title, children: _jsx(AllMembersCard, {}) })] }) }, "2")), _jsx(TabPane, { tab: strings.general.about, disabled: !isLogged, children: _jsxs("div", { style: { display: 'grid', rowGap: isMobile ? 16 : 24 }, children: [_jsx(Header, {}), ((_p = (_o = (_m = community.boards) === null || _m === void 0 ? void 0 : _m.detail) === null || _o === void 0 ? void 0 : _o[b_pk].item) === null || _p === void 0 ? void 0 : _p.description) && (_jsx(CommunityAbout, { description: (_q = community.boards.detail[b_pk].item) === null || _q === void 0 ? void 0 : _q.description })), ((_r = community.item) === null || _r === void 0 ? void 0 : _r.rule) && _jsx(CommunityRules, { isCompact: false, rules: (_s = community.item) === null || _s === void 0 ? void 0 : _s.rule })] }) }, "3")] })] })), !isMobile && (_jsx(ContentLayout, { left: _jsx(_Fragment, { children: firstScreen }), right: _jsxs("div", { style: {
                            display: 'grid',
                            columnGap: isSmall ? 0 : 16,
                            rowGap: isMobile ? 16 : 24,
                        }, children: [isLogged && !isCompleteProfile && _jsx(CompleteProfile, {}), cm_pk != 'turingo' && _jsx(MembersCard, {}), ((_v = (_u = (_t = community.boards) === null || _t === void 0 ? void 0 : _t.detail) === null || _u === void 0 ? void 0 : _u[b_pk].item) === null || _v === void 0 ? void 0 : _v.description) && (_jsx(CommunityAbout, { description: (_w = community.boards.detail[b_pk].item) === null || _w === void 0 ? void 0 : _w.description })), ((_x = community.item) === null || _x === void 0 ? void 0 : _x.rule) && _jsx(CommunityRules, { isCompact: false, rules: (_y = community.item) === null || _y === void 0 ? void 0 : _y.rule })] }) }))] }));
    }
};
