import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
export const CatalogueItem4 = (props) => {
    return (_jsx(Icon, Object.assign({ component: () => {
            if (props.filled) {
                return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: '100%', viewBox: "0 0 24 24", fill: 'currentColor', children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.9011 6.59263H21.8546C22.3287 6.59263 22.7118 6.97567 22.7118 7.44978V11.1992H12.9971V6.48828H10.9971V11.1992H1.2832V7.44978C1.2832 6.97567 1.66624 6.59263 2.14035 6.59263H6.09392C5.72695 6.01942 5.51535 5.33906 5.51535 4.61049C5.51535 2.5721 7.17338 0.914062 9.21178 0.914062C10.3207 0.914062 11.3198 1.40692 11.9975 2.18371C12.6752 1.40692 13.6743 0.914062 14.7832 0.914062C16.8216 0.914062 18.4796 2.5721 18.4796 4.61049C18.4796 5.33906 18.2653 6.01942 17.9011 6.59263ZM14.7832 2.73549C13.7493 2.73549 12.9082 3.57656 12.9082 4.61049V6.48549H14.7832C15.8171 6.48549 16.6582 5.64442 16.6582 4.61049C16.6582 3.57656 15.8171 2.73549 14.7832 2.73549ZM11.0868 4.61049C11.0868 3.57656 10.2457 2.73549 9.21178 2.73549C8.17785 2.73549 7.33677 3.57656 7.33677 4.61049C7.33677 5.64442 8.17785 6.48549 9.21178 6.48549H11.0868V4.61049Z" }), _jsx("path", { d: "M10.9971 13.0292H2.56892V22.2355C2.56892 22.7096 2.95195 23.0926 3.42606 23.0926H10.9971V13.0292Z" }), _jsx("path", { d: "M12.9971 23.0926H20.5689C21.043 23.0926 21.4261 22.7096 21.4261 22.2355V13.0292H12.9971L12.9971 23.0926Z" })] }));
            }
            else {
                return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: '100%', viewBox: "0 0 24 24", fill: 'currentColor', children: _jsx("path", { d: "M21.8576 6.59263H17.904C18.2683 6.01942 18.4826 5.33906 18.4826 4.61049C18.4826 2.5721 16.8245 0.914062 14.7861 0.914062C13.6772 0.914062 12.6781 1.40692 12.0004 2.18371C11.3227 1.40692 10.3236 0.914062 9.2147 0.914062C7.17631 0.914062 5.51828 2.5721 5.51828 4.61049C5.51828 5.33906 5.72988 6.01942 6.09685 6.59263H2.14328C1.66917 6.59263 1.28613 6.97567 1.28613 7.44978V12.8069C1.28613 12.9248 1.38256 13.0212 1.50042 13.0212H2.57185V22.2355C2.57185 22.7096 2.95488 23.0926 3.42899 23.0926H20.5718C21.046 23.0926 21.429 22.7096 21.429 22.2355V13.0212H22.5004C22.6183 13.0212 22.7147 12.9248 22.7147 12.8069V7.44978C22.7147 6.97567 22.3317 6.59263 21.8576 6.59263ZM12.9111 4.61049C12.9111 3.57656 13.7522 2.73549 14.7861 2.73549C15.8201 2.73549 16.6611 3.57656 16.6611 4.61049C16.6611 5.64442 15.8201 6.48549 14.7861 6.48549H12.9111V4.61049ZM9.2147 2.73549C10.2486 2.73549 11.0897 3.57656 11.0897 4.61049V6.48549H9.2147C8.18078 6.48549 7.3397 5.64442 7.3397 4.61049C7.3397 3.57656 8.18078 2.73549 9.2147 2.73549ZM3.10756 11.1998V8.41406H11.0897V11.1998H3.10756ZM4.39328 13.0212H11.0897V21.2712H4.39328V13.0212ZM19.6076 21.2712H12.9111V13.0212H19.6076V21.2712ZM20.8933 11.1998H12.9111V8.41406H20.8933V11.1998Z" }) }));
            }
        } }, props)));
};
