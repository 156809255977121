import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import { Affix } from 'antd';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { communityList } from '@state/actions';
import { CommunityLayout } from './CommunityLayout';
import { hideSidebarRoutes } from './Application';
import { SidebarCommunities } from '@components/navegation/sider/Siderbar/SidebarCommunities';
import { useLocation } from 'react-router';
const CommunitiesLayout = () => {
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const location = useLocation();
    const validation = useAppSelector((state) => state.auth.authValidation);
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const communities = useAppSelector((state) => state.auth.communities);
    const hideSidebar = hideSidebarRoutes(location.pathname, validation);
    useEffect(() => {
        if (isLogged) {
            void dispatch(communityList({}));
        }
    }, [isLogged]);
    const isAdminPanel = location.pathname.split('/')[2] == 'settings';
    const showCommunities = useMemo(() => {
        var _a;
        return communities && !communities.loading && ((_a = communities.items) === null || _a === void 0 ? void 0 : _a.length) > 1 && !isMobile && !hideSidebar && !isAdminPanel && isLogged;
    }, [communities, isMobile, hideSidebar, isAdminPanel, isLogged]);
    return (_jsxs(_Fragment, { children: [showCommunities && (_jsx(Affix, { offsetTop: 48, style: { zIndex: 9 }, children: _jsx("div", { className: "turingo-sidebar", style: { display: 'inline-flex' }, children: _jsx(SidebarCommunities, { kind: "sidebar", communities: communities.items }) }) })), _jsx(CommunityLayout, {})] }));
};
export { CommunitiesLayout };
