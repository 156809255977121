import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Row, Tooltip } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import useBreakpoints from '@hooks/useBreakpoints';
import { debounce } from 'lodash';
const MonikerComponent = (props) => {
    const { tags, uniquePk, style, maxDivSize, offset = 32 } = props;
    const [visibleTags, setVisibleTags] = useState([]);
    const [hiddenTags, setHiddenTags] = useState([]);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    useEffect(() => {
        setVisibleTags([]);
        setHiddenTags([]);
    }, [isMobile]);
    useEffect(() => {
        const handleResize = debounce(() => {
            truncate();
        }, 1000);
        truncate();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [tags]);
    const truncate = () => {
        const div = document.getElementById(`tags-container-${uniquePk}`);
        if (div) {
            let totalSize = 0;
            const visibleTags = [];
            const hiddenTags = [];
            tags.forEach((topic) => {
                const etiquetaElement = document.getElementById(`topic-${uniquePk}-${topic}`);
                const originalDisplay = etiquetaElement.style.display;
                etiquetaElement.style.display = 'block';
                const width = etiquetaElement.offsetWidth;
                etiquetaElement.style.display = originalDisplay;
                if (etiquetaElement) {
                    const etiquetaSize = width;
                    if (totalSize + etiquetaSize <= div.offsetWidth - offset) {
                        totalSize += etiquetaSize;
                        visibleTags.push(topic);
                    }
                    else {
                        hiddenTags.push(topic);
                    }
                }
                else {
                    hiddenTags.push(topic);
                }
            });
            setVisibleTags(visibleTags);
            setHiddenTags(hiddenTags);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { style: Object.assign({ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap', width: '100%' }, style), children: tags.map((topic, index) => {
                    return (_jsxs(Paragraph, { style: { marginRight: 4, display: 'none' }, id: `topic-${uniquePk}-${topic}`, children: [topic, " ", index < tags.length - 1 && ' •'] }, index));
                }) }), _jsxs(Row, { style: Object.assign({ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap', width: '100%', maxWidth: maxDivSize ? maxDivSize : 'calc(100vw - ' + 32 + 'px)' }, style), id: `tags-container-${uniquePk}`, children: [visibleTags.map((topic, index) => {
                        return (_jsxs(Paragraph, { style: { marginRight: 4 }, children: [topic, " ", index < visibleTags.length - 1 && ' •'] }, index));
                    }), hiddenTags.length > 0 && (_jsx(Tooltip, { trigger: ['click', 'hover'], style: { cursor: 'pointer' }, title: hiddenTags.map((topic) => {
                            return _jsxs("div", { children: [" ", topic] }, `hidden-${uniquePk}-${topic}`);
                        }), children: _jsxs("span", { style: { cursor: 'pointer' }, children: ["+", hiddenTags.length] }) }))] })] }));
};
export { MonikerComponent };
