import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAppSelector } from '@hooks/useStore';
import { Redirect } from 'react-router';
import { Router } from '@Router';
export const getFirstCommunity = (to, communities) => {
    const copyCommunities = communities.items ? [...communities.items] : [];
    const communitiesOrdered = copyCommunities === null || copyCommunities === void 0 ? void 0 : copyCommunities.sort((a, b) => a.name.localeCompare(b.name));
    if (to) {
        return to;
    }
    if ((communitiesOrdered === null || communitiesOrdered === void 0 ? void 0 : communitiesOrdered.length) == 1) {
        return communitiesOrdered[0].publicKey;
    }
    else if ((communitiesOrdered === null || communitiesOrdered === void 0 ? void 0 : communitiesOrdered.length) > 1) {
        const communityWithoutTuringo = communitiesOrdered.filter((c) => c.publicKey != 'turingo');
        if (communityWithoutTuringo.length == 0) {
            return 'turingo';
        }
        else {
            return communityWithoutTuringo[0].publicKey;
        }
    }
};
export const redirectFunction = (to, communities) => {
    const copyCommunities = communities.items ? [...communities.items] : [];
    const communitiesOrdered = copyCommunities === null || copyCommunities === void 0 ? void 0 : copyCommunities.sort((a, b) => a.name.localeCompare(b.name));
    if (to) {
        return to;
    }
    if ((communitiesOrdered === null || communitiesOrdered === void 0 ? void 0 : communitiesOrdered.length) == 1) {
        return Router.Path.community_home({ cm_pk: communitiesOrdered[0].publicKey });
    }
    else if ((communitiesOrdered === null || communitiesOrdered === void 0 ? void 0 : communitiesOrdered.length) > 1) {
        const communityWithoutTuringo = communitiesOrdered.filter((c) => c.publicKey != 'turingo');
        if (communityWithoutTuringo.length == 0) {
            return Router.Path.community_home({ cm_pk: 'turingo' });
        }
        else {
            return Router.Path.community_home({ cm_pk: communityWithoutTuringo[0].publicKey });
        }
    }
};
export const RedirectComponent = (props) => {
    const communities = useAppSelector((state) => state.auth.communities);
    const copyCommunities = communities.items ? [...communities.items] : [];
    const communitiesOrdered = copyCommunities === null || copyCommunities === void 0 ? void 0 : copyCommunities.sort((a, b) => a.name.localeCompare(b.name));
    const { to } = props;
    if (to) {
        return _jsx(Redirect, { to: to });
    }
    if ((communitiesOrdered === null || communitiesOrdered === void 0 ? void 0 : communitiesOrdered.length) == 1) {
        return _jsx(Redirect, { to: { pathname: Router.Path.community_home({ cm_pk: communitiesOrdered[0].publicKey }) } });
    }
    else if ((communitiesOrdered === null || communitiesOrdered === void 0 ? void 0 : communitiesOrdered.length) > 1) {
        const communityWithoutTuringo = communitiesOrdered.filter((c) => c.publicKey != 'turingo');
        if (communityWithoutTuringo.length == 0) {
            return _jsx(Redirect, { to: { pathname: Router.Path.community_home({ cm_pk: 'turingo' }) } });
        }
        else {
            return _jsx(Redirect, { to: { pathname: Router.Path.community_home({ cm_pk: communityWithoutTuringo[0].publicKey }) } });
        }
    }
    return _jsx(_Fragment, {});
};
