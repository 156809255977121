import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton';
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton';
export const FooterModal = (props) => {
    const { style, back, primary, secondary } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const btns = (secondary || primary) && (_jsxs(Row, { justify: "end", style: style, children: [secondary && secondary.action && (_jsx(Col, { span: isMobile ? 24 : null, order: isMobile ? 2 : 1, children: _jsx(TuringoButton, { type: 'default', expandInMobile: true, style: { marginBottom: isMobile && back && back.action ? 16 : 0 }, onClick: secondary.action, children: secondary.customText || strings.general.cancel }) })), primary && primary.action && (_jsx(Col, { span: isMobile ? 24 : null, order: isMobile ? 1 : 2, children: _jsx(TuringoButton, { disabled: primary.disabled, loading: primary.loading, expandInMobile: true, style: { marginBottom: isMobile ? 16 : 0, marginLeft: isMobile ? 0 : 16 }, type: 'primary', onClick: primary.action, children: primary.customText || strings.general.continue }) }))] }));
    return (_jsx("div", { style: {
            backgroundColor: 'var(--background)',
            display: 'flex',
            justifyContent: back && back.action ? 'space-between' : 'end',
            alignItems: isMobile ? 'normal' : 'center',
            flexDirection: isMobile ? 'column-reverse' : 'initial',
        }, children: _jsxs("div", { style: {
                display: 'flex',
                justifyContent: isMobile ? 'center' : back && back.action ? 'space-between' : 'end',
                width: '100%',
                flexDirection: isMobile ? 'column-reverse' : 'row',
            }, children: [back && back.kind == 'link' && back.action && (_jsx(TuringoButton, { type: "link", style: { alignSelf: isMobile ? 'center' : 'center', marginTop: isMobile ? 26 : 0, whiteSpace: 'nowrap' }, onClick: back.action, children: back.customText })), back && back.kind == 'button' && back.action && (_jsx(TuringoButton, { disabled: back.disabled, expandInMobile: true, type: "link", style: { alignSelf: isMobile ? 'center' : 'center', whiteSpace: 'nowrap' }, onClick: back.action, children: back.customText })), back && back.kind == 'back' && _jsx(GoBackButton, { showText: true, onClick: back.action, block: isMobile }), btns] }) }));
};
