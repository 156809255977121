import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Card } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import Meta from 'react-meta-tags';
import { CertificateListCard } from '@components/content/certificate_supplementary/CertificateListCard';
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout';
import useBreakpoints from '@hooks/useBreakpoints';
import { EntityScreenSkeleton } from '@components/skeletons/EntityScreenSkeleton';
import { communityMemberDetails, communityMemberSchema } from '@state/actions';
import { UserHeader } from '@components/content/entity/user_header/UserHeader';
import strings from '@resources/localization';
import { NotificationContext } from 'src/SocketComponent';
import { dynamicTabName } from '@util/string/Functions';
import { hasError } from '@util/StateHandler';
import { useRequestState } from '@api/requests/AsyncRequests';
import { PropertyPreview } from '../admin_panel/screens/profiles/components/PropertyPreview';
import { filterArchived, separateByPlacement } from '../admin_panel/screens/profiles/Utils';
import { UserModal } from '@components/content/entity/user_header/components/user_modal/UserModal';
const Profile = () => {
    var _a, _b, _c, _d, _e;
    const { cm_pk, u_pk } = useParams();
    const user = useAppSelector((state) => state.user.items[u_pk]);
    const [schema, setSchema] = useRequestState();
    const dispatch = useAppDispatch();
    const notifications = useContext(NotificationContext);
    useEffect(() => {
        void dispatch(communityMemberDetails({ urlParams: { cm_pk, u_pk } }));
        void dispatch(communityMemberSchema({
            urlParams: { cm_pk },
            searchParams: { archived: false },
            options: { redux: { stateHandler: setSchema, retrieveData: true } },
        }));
    }, []);
    const location = useLocation();
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const isSmall = breakpoints.isSmall;
    const [visibleEditModal, setVisibleEditModal] = useState(false);
    const openEditModal = () => {
        setVisibleEditModal(true);
    };
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const categoryHasValue = (category) => {
        return category.properties.some((p) => { var _a, _b, _c; return (_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.item) === null || _a === void 0 ? void 0 : _a.memberProperties) === null || _b === void 0 ? void 0 : _b[p.id]) === null || _c === void 0 ? void 0 : _c.value; });
    };
    if (!user || ((_a = user.state) === null || _a === void 0 ? void 0 : _a.loading) || !schema || schema.loading || !schema.data) {
        return _jsx(EntityScreenSkeleton, {});
    }
    else if (hasError([user.state])) {
        return _jsx(EntityScreenSkeleton, { error: true });
    }
    else {
        if (user.item) {
            const schemaByPlacement = separateByPlacement(filterArchived(schema.data.categories));
            const primeryCategory = schema.data.categories.find((c) => c.placement == 'primary');
            const isPrivate = user.item.visibility == 'private';
            const isLoggedUser = (currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.publicKey) == u_pk;
            return (_jsxs(_Fragment, { children: [_jsxs(Meta, { children: [_jsx("title", { children: dynamicTabName(strings.screens.profile.browserTitle, notifications) }), _jsx("meta", { name: "description", content: strings.screens.profile.browserDescription })] }), _jsx(ContentLayout, { left: _jsxs("div", { style: { display: 'grid', rowGap: isMobile ? 16 : 24, gridTemplateColumns: 'repeat(1, minmax(0, 1fr))' }, children: [visibleEditModal && _jsx(UserModal, { schema: schema.data, setVisibility: setVisibleEditModal, open: visibleEditModal }), _jsxs(Card, { bordered: false, bodyStyle: { padding: 0 }, children: [_jsx(UserHeader, { openEditModal: openEditModal, showBreadcrumb: true, from: ((_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.from.includes('directory'))
                                                ? 'directory'
                                                : ((_c = location === null || location === void 0 ? void 0 : location.state) === null || _c === void 0 ? void 0 : _c.from.includes('members'))
                                                    ? 'members'
                                                    : undefined }), _jsx("div", { style: { padding: isSmall ? '0px 16px 16px 16px' : '0px 24px 16px 24px' }, children: primeryCategory === null || primeryCategory === void 0 ? void 0 : primeryCategory.properties.sort((a, b) => Number(b.highlight) - Number(a.highlight)).map((property, index) => {
                                                return (_jsx("div", { style: { marginBottom: index < primeryCategory.properties.length - 1 ? 16 : 0 }, children: property && (_jsx(PropertyPreview, { ownership: isLoggedUser ? 'self' : 'other', user: user.item, property: property })) }, property.id));
                                            }) })] }), (_d = schemaByPlacement.center) === null || _d === void 0 ? void 0 : _d.map((category) => {
                                    if (category.placement != 'primary' && (isLoggedUser || categoryHasValue(category))) {
                                        return (_jsxs(Card, { title: category.title, children: [isLoggedUser && category.properties.length == 0 && (_jsx(Paragraph, { className: "turingo-text", style: { color: 'var(--secondary-text)' }, children: strings.screens.profile.notAdded })), category.properties.map((property, index) => {
                                                    if (property) {
                                                        return (_jsx(PropertyPreview, { ownership: isLoggedUser ? 'self' : 'other', user: user.item, style: { marginBottom: index < category.properties.length - 1 ? 16 : 0 }, property: property }, property.id));
                                                    }
                                                })] }, category.id));
                                    }
                                }), isPrivate && !isLoggedUser && currentEntity && (_jsx(Alert, { showIcon: true, message: 'Esta cuenta es privada', icon: _jsx(LockOutlined, {}) })), !isPrivate || !currentEntity || isLoggedUser ? (_jsx(_Fragment, { children: isMobile && _jsx(CertificateListCard, { isPrivate: isPrivate, userPk: u_pk }) })) : null] }), right: !isPrivate || !currentEntity || isLoggedUser ? (_jsxs("div", { style: { display: 'grid', rowGap: isMobile ? 16 : 24, columnGap: isSmall ? 0 : 16 }, children: [(_e = schemaByPlacement.right) === null || _e === void 0 ? void 0 : _e.map((category) => {
                                    if (isLoggedUser || categoryHasValue(category)) {
                                        return (_jsxs(Card, { title: category.title, children: [isLoggedUser && category.properties.length == 0 && (_jsx(Paragraph, { className: "turingo-text", style: { color: 'var(--secondary-text)' }, children: strings.screens.profile.notAdded })), category.properties.map((property, index) => {
                                                    if (property) {
                                                        return (_jsx(PropertyPreview, { ownership: isLoggedUser ? 'self' : 'other', user: user.item, style: { marginBottom: index < category.properties.length - 1 ? 16 : 0 }, property: property }, property.id));
                                                    }
                                                })] }, category.id));
                                    }
                                }), !isMobile && _jsx(CertificateListCard, { isPrivate: isPrivate, userPk: u_pk })] })) : null })] }));
        }
        else {
            return _jsx(_Fragment, {});
        }
    }
};
export { Profile };
