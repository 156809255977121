import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useParams } from 'react-router';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import strings from '@resources/localization';
import { ReportModal } from '@components/modals/report_modal/ReportModal';
import { CommentModal } from './CommentModal';
import { MoreButton } from '@components/buttons/more_button/MoreButton';
import { PostContext } from '@components/content/posts/Post';
export const CommentMenu = (props) => {
    var _a, _b, _c, _d;
    const { comment, onEdit, parents, b_pk } = props;
    const [visible, setVisible] = useState(false);
    const [modalType, setModalType] = useState('edit');
    const dispatch = useAppDispatch();
    const { cm_pk } = useParams();
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const { mask } = useContext(PostContext);
    const [visibleReport, setVisibleReport] = useState(false);
    const boardAccess = (_b = (_a = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.community.items[cm_pk]) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.boards; })) === null || _a === void 0 ? void 0 : _a.find((board) => board.publicKey == b_pk)) === null || _b === void 0 ? void 0 : _b.access;
    const handleCancel = () => {
        setVisible(false);
        setVisibleReport(false);
    };
    const handleReport = () => {
        setVisibleReport(true);
    };
    const handleEdit = () => {
        onEdit();
    };
    const handleDelete = () => {
        setModalType('delete');
        setVisible(true);
    };
    const belongsToUser = comment.author.publicKey === ((_c = mask.get) === null || _c === void 0 ? void 0 : _c.publicKey);
    const menuItems = [
        ...(belongsToUser
            ? [
                {
                    label: strings.general.edit,
                    key: 'comment#edit',
                    onClick: handleEdit,
                    icon: _jsx(EditOutlined, {}),
                },
            ]
            : []),
        ...(!belongsToUser
            ? [
                {
                    label: strings.general.report,
                    key: 'comment#report',
                    onClick: handleReport,
                    icon: _jsx(ExclamationCircleOutlined, {}),
                },
            ]
            : []),
        ...(belongsToUser || ((_d = boardAccess === null || boardAccess === void 0 ? void 0 : boardAccess.posts) === null || _d === void 0 ? void 0 : _d.includes('edit'))
            ? [
                {
                    label: strings.general.delete,
                    key: 'comment#delete',
                    onClick: handleDelete,
                    icon: _jsx(DeleteOutlined, {}),
                },
            ]
            : []),
    ];
    return (_jsxs("div", { children: [_jsx(CommentModal, { parents: parents, type: modalType, open: visible, onCancel: handleCancel, comment: comment, b_pk: b_pk }), _jsx(ReportModal, { kind: 'comment', reference: { b_pk: b_pk, p_pk: comment.publicKey }, open: visibleReport, onCancel: handleCancel }), _jsx(MoreButton, { size: 'small', type: "text", items: menuItems })] }));
};
