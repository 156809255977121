import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { postReaction, postReactionDelete } from '@state/actions';
import { debounce } from 'lodash';
import { useParams } from 'react-router';
import { LikeIcon } from '@components/icons/LikeIcon';
import { PostContext } from '../../Post';
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton';
export const CommentReactAction = (props) => {
    const { reaction, target_pk, b_pk, totalReactions, parents } = props;
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const dispatch = useAppDispatch();
    (reaction === null || reaction === void 0 ? void 0 : reaction.content) == 'like';
    const { mask } = useContext(PostContext);
    const { cm_pk, b_pk: br_pk, p_pk: pr_pk } = useParams();
    const isFeed = !br_pk;
    const scope = pr_pk ? 'detail' : 'all';
    const like = () => {
        var _a, _b;
        void dispatch(postReaction({
            urlParams: { cm_pk, b_pk, target_pk: target_pk },
            bodyParams: Object.assign(Object.assign({}, (currentEntity.publicKey != ((_a = mask.get) === null || _a === void 0 ? void 0 : _a.publicKey) && { mask: (_b = mask.get) === null || _b === void 0 ? void 0 : _b.publicKey })), { kind: 'react', content: 'like', parents }),
            extraParams: { scope: scope, feed: isFeed },
        }));
    };
    const dislike = () => {
        var _a, _b;
        void dispatch(postReactionDelete({
            urlParams: { cm_pk, b_pk, target_pk: target_pk },
            bodyParams: Object.assign(Object.assign({}, (currentEntity.publicKey != ((_a = mask.get) === null || _a === void 0 ? void 0 : _a.publicKey) && { mask: (_b = mask.get) === null || _b === void 0 ? void 0 : _b.publicKey })), { kind: 'react', content: 'like', parents }),
            extraParams: { scope: scope, feed: isFeed },
        }));
    };
    const likeDebounce = debounce(like, 0);
    const dislikeDebounce = debounce(dislike, 0);
    return (_jsx(TuringoButton, { style: Object.assign({}, ((reaction === null || reaction === void 0 ? void 0 : reaction.content) == 'like' && { color: 'var(--ant-primary-color)' })), onClick: (reaction === null || reaction === void 0 ? void 0 : reaction.content) == 'like' ? dislikeDebounce : likeDebounce, shape: "standard", size: "small", type: "reaction", icon: _jsx(LikeIcon, { filled: (reaction === null || reaction === void 0 ? void 0 : reaction.content) == 'like' }), children: totalReactions > 0 && _jsx("div", { style: { marginLeft: 2 }, children: totalReactions }) }));
};
