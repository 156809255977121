import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { Alert, Card, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { PostContext } from '@components/content/posts/Post';
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton';
import { ViewList } from '@icon-park/react';
import strings from '@resources/localization';
import Paragraph from 'antd/lib/typography/Paragraph';
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody';
import { useHistory } from 'react-router';
import { KindNamePlural } from '@util/string/ModelNames';
import { API } from '@api/API';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { FooterModal } from '@components/modals/components/FooterModal';
import { CourseProgress } from '@components/content/certificate_supplementary/CourseProgress';
import { QuizAnswerForm } from './QuizAnswerForm';
import { postList } from '@state/actions';
import { CertificateModal } from '@components/content/certificate_supplementary/CertificateModal';
import dayjs from 'dayjs';
const QuizResultsScreen = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20;
    const { cm_pk, b_pk, p_pk } = useContext(PostContext);
    const course = useAppSelector((state) => state.community.items[cm_pk].boards.detail[b_pk]);
    const lesson = useAppSelector((state) => { var _a; return (_a = state.posts.detail[b_pk]) === null || _a === void 0 ? void 0 : _a.items[p_pk]; });
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const [showCertificate, setShowCertificate] = useState(false);
    const history = useHistory();
    const [score, setScore] = useState((_c = (_b = (_a = lesson === null || lesson === void 0 ? void 0 : lesson.item) === null || _a === void 0 ? void 0 : _a.quiz) === null || _b === void 0 ? void 0 : _b.results) === null || _c === void 0 ? void 0 : _c.score);
    const [isApproved, setIsApproved] = useState(score >= ((_f = (_e = (_d = lesson === null || lesson === void 0 ? void 0 : lesson.item) === null || _d === void 0 ? void 0 : _d.quiz) === null || _e === void 0 ? void 0 : _e.scoring) === null || _f === void 0 ? void 0 : _f.passing));
    const [haveAttempts, setHaveAttempts] = useState(((_h = (_g = lesson.item.quiz) === null || _g === void 0 ? void 0 : _g.results) === null || _h === void 0 ? void 0 : _h.repetition) === undefined || ((_k = (_j = lesson.item.quiz) === null || _j === void 0 ? void 0 : _j.options) === null || _k === void 0 ? void 0 : _k.repetitions) >= ((_m = (_l = lesson.item.quiz) === null || _l === void 0 ? void 0 : _l.results) === null || _m === void 0 ? void 0 : _m.repetition));
    const lessons = useAppSelector((state) => state.posts.all[b_pk]);
    const [attempt, setAttempt] = useState((_p = (_o = lesson.item.quiz) === null || _o === void 0 ? void 0 : _o.results) === null || _p === void 0 ? void 0 : _p.repetition);
    const attemptsLeft = ((_r = (_q = lesson.item.quiz) === null || _q === void 0 ? void 0 : _q.options) === null || _r === void 0 ? void 0 : _r.repetitions) + 1 - attempt;
    const dontAllowRepetitions = ((_t = (_s = lesson.item.quiz) === null || _s === void 0 ? void 0 : _s.options) === null || _t === void 0 ? void 0 : _t.repetitions) == 0;
    const showResults = (_v = (_u = lesson.item.quiz) === null || _u === void 0 ? void 0 : _u.options) === null || _v === void 0 ? void 0 : _v.showResults;
    const showCorrectAnswers = (_x = (_w = lesson.item.quiz) === null || _w === void 0 ? void 0 : _w.options) === null || _x === void 0 ? void 0 : _x.showCorrectAnswers;
    const isEvaluation = ((_y = lesson.item.quiz) === null || _y === void 0 ? void 0 : _y.scoring.passing) != undefined && ((_z = lesson.item.quiz) === null || _z === void 0 ? void 0 : _z.scoring.passing) != 0;
    const dispatch = useAppDispatch();
    const [showProgress, setShowProgress] = useState();
    const hasCertificate = ((_1 = (_0 = lesson.item.board) === null || _0 === void 0 ? void 0 : _0.merits) === null || _1 === void 0 ? void 0 : _1.find((m) => m.merit.kind == 'certificate')) && !!((_4 = (_3 = (_2 = course === null || course === void 0 ? void 0 : course.item) === null || _2 === void 0 ? void 0 : _2.course) === null || _3 === void 0 ? void 0 : _3.assign) === null || _4 === void 0 ? void 0 : _4.cert);
    const [quiz, setQuiz] = useState({ data: undefined, loading: false });
    const isBetweenLessons = ((_5 = quiz === null || quiz === void 0 ? void 0 : quiz.data) === null || _5 === void 0 ? void 0 : _5.lessonsLeft) !== undefined ? (_6 = quiz === null || quiz === void 0 ? void 0 : quiz.data) === null || _6 === void 0 ? void 0 : _6.lessonsLeft : (_7 = lesson.item.quiz) === null || _7 === void 0 ? void 0 : _7.lessonsLeft;
    const [quizComplete, setQuizComplete] = useState(false);
    const isReleased = ((_9 = (_8 = lesson.item.quiz) === null || _8 === void 0 ? void 0 : _8.options) === null || _9 === void 0 ? void 0 : _9.dateFrom) ? new Date((_11 = (_10 = lesson.item.quiz) === null || _10 === void 0 ? void 0 : _10.options) === null || _11 === void 0 ? void 0 : _11.dateFrom) <= new Date() : true;
    const isSequential = (_13 = (_12 = lesson.item.quiz) === null || _12 === void 0 ? void 0 : _12.options) === null || _13 === void 0 ? void 0 : _13.sequential;
    const retrieveQuiz = () => {
        setQuiz({ data: undefined, loading: true });
        void API.quizGet({
            urlParams: {
                cm_pk,
                b_pk,
                p_pk,
            },
        }).then((result) => {
            setAttempt(0);
            if ('id' in result.data) {
                setQuiz({ data: result.data, loading: false });
            }
            else {
                setQuiz({ data: undefined, loading: false });
                setShowProgress(result.data);
            }
        });
    };
    const getActionText = (action) => {
        switch (action) {
            case 'review':
                return strings.screens.admin.quizzes.review;
            case 'repeat':
                return strings.screens.admin.quizzes.repeat;
            case 'finish':
                return strings.screens.admin.quizzes.finish;
            case 'continue':
                return strings.general.continue;
            case 'certificate':
                return strings.screens.admin.quizzes.certificate;
            default:
                return strings.general.continue;
        }
    };
    const getAction = (action) => {
        var _a, _b;
        if (action == 'review') {
            const firstLesson = (_a = Object.values(lessons === null || lessons === void 0 ? void 0 : lessons.items)) === null || _a === void 0 ? void 0 : _a[0];
            history.push(`/${cm_pk}/${KindNamePlural[course.item.kind]}/${course.item.publicKey}/${firstLesson.item.publicKey}`);
        }
        if (action == 'repeat') {
            setQuizComplete(false);
            retrieveQuiz();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        else if (action == 'finish') {
            history.push(`/${cm_pk}/${KindNamePlural[course.item.kind]}/${course.item.publicKey}`);
        }
        else if (action == 'continue') {
            let quizFound = false;
            let quizPk = null;
            const lessonsValues = lessons.items ? (_b = Object.values(lessons.items)) === null || _b === void 0 ? void 0 : _b.sort((a, b) => a.item.contentSequence - b.item.contentSequence) : [];
            for (const lesson of lessonsValues) {
                if (lesson.item.publicKey === p_pk) {
                    quizFound = true;
                }
                else if (quizFound) {
                    quizPk = lesson.item.publicKey;
                }
            }
            history.push(`/${cm_pk}/${KindNamePlural[course.item.kind]}/${course.item.publicKey}/${quizPk}`);
        }
        else if (action == 'certificate') {
            setShowCertificate(true);
        }
    };
    function getText() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        const isComplete = isApproved || !isEvaluation || !showResults;
        if (isComplete) {
            if (isBetweenLessons) {
                if (hasCertificate) {
                    return {
                        title: strings.formatString(strings.screens.evaluation.quiz.results.complete.title, currentEntity.name),
                        description: strings.formatString(strings.screens.evaluation.quiz.results.complete.betweenLesson.certificate, (_a = course === null || course === void 0 ? void 0 : course.item) === null || _a === void 0 ? void 0 : _a.name),
                        action: ['continue'],
                    };
                }
                else {
                    return {
                        title: strings.formatString(strings.screens.evaluation.quiz.results.complete.title, currentEntity.name),
                        description: strings.formatString(strings.screens.evaluation.quiz.results.complete.betweenLesson.noCertificate, (_b = course === null || course === void 0 ? void 0 : course.item) === null || _b === void 0 ? void 0 : _b.name),
                        action: ['continue'],
                    };
                }
            }
            else {
                if (hasCertificate) {
                    return {
                        title: strings.formatString(strings.screens.evaluation.quiz.results.complete.title, currentEntity.name),
                        description: strings.formatString(strings.screens.evaluation.quiz.results.complete.finish.certificate, (_c = course === null || course === void 0 ? void 0 : course.item) === null || _c === void 0 ? void 0 : _c.name),
                        subdescription: strings.screens.evaluation.quiz.results.lookupCertificates,
                        action: ['certificate'],
                    };
                }
                else {
                    return {
                        title: strings.formatString(strings.screens.evaluation.quiz.results.complete.title, currentEntity.name),
                        description: strings.formatString(strings.screens.evaluation.quiz.results.complete.finish.noCertificate, (_d = course === null || course === void 0 ? void 0 : course.item) === null || _d === void 0 ? void 0 : _d.name),
                        action: ['finish'],
                    };
                }
            }
        }
        else if (isApproved && showResults) {
            if (showCorrectAnswers) {
                if (isBetweenLessons) {
                    if (hasCertificate) {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name),
                            description: strings.formatString(strings.screens.evaluation.quiz.results.approve.showCorrectAnswers.betweenLesson.certificate, (_e = course === null || course === void 0 ? void 0 : course.item) === null || _e === void 0 ? void 0 : _e.name, score === null || score === void 0 ? void 0 : score.toString()),
                            action: ['continue'],
                        };
                    }
                    else {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name),
                            description: strings.formatString(strings.screens.evaluation.quiz.results.approve.showCorrectAnswers.betweenLesson.noCertificate, (_f = course === null || course === void 0 ? void 0 : course.item) === null || _f === void 0 ? void 0 : _f.name, score === null || score === void 0 ? void 0 : score.toString()),
                            action: ['continue'],
                        };
                    }
                }
                else {
                    if (hasCertificate) {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name),
                            description: strings.formatString(strings.screens.evaluation.quiz.results.approve.showCorrectAnswers.finish.certificate, (_g = course === null || course === void 0 ? void 0 : course.item) === null || _g === void 0 ? void 0 : _g.name, score === null || score === void 0 ? void 0 : score.toString()),
                            action: ['certificate'],
                        };
                    }
                    else {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name),
                            description: strings.formatString(strings.screens.evaluation.quiz.results.approve.showCorrectAnswers.finish.noCertificate, (_h = course === null || course === void 0 ? void 0 : course.item) === null || _h === void 0 ? void 0 : _h.name, score === null || score === void 0 ? void 0 : score.toString()),
                            action: ['finish'],
                        };
                    }
                }
            }
            else {
                if (isBetweenLessons) {
                    if (hasCertificate) {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name),
                            description: strings.formatString(strings.screens.evaluation.quiz.results.approve.noShowCorrectAnswers.betweenLesson.certificate, (_j = course === null || course === void 0 ? void 0 : course.item) === null || _j === void 0 ? void 0 : _j.name),
                            action: ['continue'],
                        };
                    }
                    else {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name),
                            description: strings.formatString(strings.screens.evaluation.quiz.results.approve.noShowCorrectAnswers.betweenLesson.noCertificate, (_k = course === null || course === void 0 ? void 0 : course.item) === null || _k === void 0 ? void 0 : _k.name),
                            action: ['continue'],
                        };
                    }
                }
                else {
                    if (hasCertificate) {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name),
                            description: strings.formatString(strings.screens.evaluation.quiz.results.approve.noShowCorrectAnswers.finish.certificate, (_l = course === null || course === void 0 ? void 0 : course.item) === null || _l === void 0 ? void 0 : _l.name),
                            action: ['certificate'],
                        };
                    }
                    else {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name),
                            description: strings.formatString(strings.screens.evaluation.quiz.results.approve.noShowCorrectAnswers.finish.noCertificate, (_m = course === null || course === void 0 ? void 0 : course.item) === null || _m === void 0 ? void 0 : _m.name),
                            action: ['finish'],
                        };
                    }
                }
            }
        }
        else {
            if (showCorrectAnswers) {
                if (isBetweenLessons) {
                    if (isSequential) {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.failure.title, currentEntity.name),
                            description: strings.formatString(strings.screens.evaluation.quiz.results.failure.showCorrectAnswers.betweenLesson.sequential, score === null || score === void 0 ? void 0 : score.toString(), (_o = course === null || course === void 0 ? void 0 : course.item) === null || _o === void 0 ? void 0 : _o.name),
                            subdescription: dontAllowRepetitions
                                ? strings.screens.evaluation.quiz.results.failure.retry.noretries
                                : haveAttempts
                                    ? isEvaluation
                                        ? strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.evaluation, attemptsLeft)
                                        : strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft)
                                    : strings.screens.evaluation.quiz.results.failure.retry.maxRetriesReached,
                            action: dontAllowRepetitions || !haveAttempts ? ['review'] : isEvaluation && isSequential ? ['repeat'] : ['repeat', 'continue'],
                        };
                    }
                    else {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.failure.title, currentEntity.name),
                            description: strings.formatString(strings.screens.evaluation.quiz.results.failure.showCorrectAnswers.betweenLesson.noSequential, score === null || score === void 0 ? void 0 : score.toString(), (_p = course === null || course === void 0 ? void 0 : course.item) === null || _p === void 0 ? void 0 : _p.name),
                            subdescription: dontAllowRepetitions
                                ? strings.screens.evaluation.quiz.results.failure.retry.noretries
                                : haveAttempts
                                    ? isEvaluation
                                        ? strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft)
                                        : strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft)
                                    : strings.screens.evaluation.quiz.results.failure.retry.maxRetriesReached,
                            action: dontAllowRepetitions || !haveAttempts ? ['continue'] : ['repeat', 'continue'],
                        };
                    }
                }
                else {
                    return {
                        title: strings.formatString(strings.screens.evaluation.quiz.results.failure.title, currentEntity.name),
                        description: strings.formatString(strings.screens.evaluation.quiz.results.failure.showCorrectAnswers.finish, score === null || score === void 0 ? void 0 : score.toString(), (_q = course === null || course === void 0 ? void 0 : course.item) === null || _q === void 0 ? void 0 : _q.name),
                        subdescription: dontAllowRepetitions
                            ? strings.screens.evaluation.quiz.results.failure.retry.noretries
                            : haveAttempts
                                ? isEvaluation
                                    ? strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.evaluation, attemptsLeft)
                                    : strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft)
                                : strings.screens.evaluation.quiz.results.failure.retry.maxRetriesReached,
                        action: dontAllowRepetitions || !haveAttempts ? ['review'] : ['repeat'],
                    };
                }
            }
            else {
                if (isBetweenLessons) {
                    if (isSequential) {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.failure.title, currentEntity.name),
                            description: strings.formatString(strings.screens.evaluation.quiz.results.failure.noShowCorrectAnswers.betweenLesson.sequential, (_r = course === null || course === void 0 ? void 0 : course.item) === null || _r === void 0 ? void 0 : _r.name),
                            subdescription: dontAllowRepetitions
                                ? strings.screens.evaluation.quiz.results.failure.retry.noretries
                                : haveAttempts
                                    ? isEvaluation
                                        ? strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.evaluation, attemptsLeft)
                                        : strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft)
                                    : strings.screens.evaluation.quiz.results.failure.retry.maxRetriesReached,
                            action: dontAllowRepetitions || !haveAttempts ? ['review'] : isEvaluation && isSequential ? ['repeat'] : ['repeat', 'continue'],
                        };
                    }
                    else {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.failure.title, currentEntity.name),
                            description: strings.formatString(strings.screens.evaluation.quiz.results.failure.noShowCorrectAnswers.betweenLesson.noSequential, (_s = course === null || course === void 0 ? void 0 : course.item) === null || _s === void 0 ? void 0 : _s.name),
                            subdescription: dontAllowRepetitions
                                ? strings.screens.evaluation.quiz.results.failure.retry.noretries
                                : haveAttempts
                                    ? isEvaluation
                                        ? strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft)
                                        : strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft)
                                    : strings.screens.evaluation.quiz.results.failure.retry.maxRetriesReached,
                            action: dontAllowRepetitions || !haveAttempts ? ['continue'] : ['repeat', 'continue'],
                        };
                    }
                }
                else {
                    return {
                        title: strings.formatString(strings.screens.evaluation.quiz.results.failure.title, currentEntity.name),
                        description: strings.formatString(strings.screens.evaluation.quiz.results.failure.noShowCorrectAnswers.finish, (_t = course === null || course === void 0 ? void 0 : course.item) === null || _t === void 0 ? void 0 : _t.name),
                        subdescription: dontAllowRepetitions
                            ? strings.screens.evaluation.quiz.results.failure.retry.noretries
                            : haveAttempts
                                ? isEvaluation
                                    ? strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.evaluation, attemptsLeft)
                                    : strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft)
                                : strings.screens.evaluation.quiz.results.failure.retry.maxRetriesReached,
                        action: dontAllowRepetitions || !haveAttempts ? ['review'] : ['repeat'],
                    };
                }
            }
        }
    }
    const [answers, setAnswers] = useState({ data: undefined, loading: false });
    const language = useAppSelector((state) => state.ui.language);
    const onSubmit = (values) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setAnswers({ data: values, loading: false });
    };
    const sendAnswers = () => {
        setAnswers((prev) => {
            return Object.assign(Object.assign({}, prev), { loading: true });
        });
        void API.answerCreate({
            urlParams: { cm_pk, b_pk, q_pk: p_pk },
            bodyParams: { answers: answers.data },
        }).then((result) => {
            var _a, _b, _c, _d, _e, _f, _g;
            setAnswers((prev) => {
                return { data: undefined, loading: false };
            });
            setScore((_a = result.data.results) === null || _a === void 0 ? void 0 : _a.score);
            setQuizComplete(true);
            setIsApproved(((_b = result.data.results) === null || _b === void 0 ? void 0 : _b.score) >= ((_d = (_c = result.data) === null || _c === void 0 ? void 0 : _c.scoring) === null || _d === void 0 ? void 0 : _d.passing));
            setAttempt((_e = result.data.results) === null || _e === void 0 ? void 0 : _e.repetition);
            setHaveAttempts(((_f = result.data.options) === null || _f === void 0 ? void 0 : _f.repetitions) >= ((_g = result.data.results) === null || _g === void 0 ? void 0 : _g.repetition));
            setQuiz({ data: result.data, loading: false });
            void dispatch(postList({ urlParams: { cm_pk, b_pk: b_pk }, searchParams: { kind: 'meta_playlist' } }));
        });
    };
    return (_jsxs("div", { children: [_jsx(CertificateModal, { download: true, open: showCertificate, hide: () => {
                    setShowCertificate(false);
                } }), _jsx(TuringoModal, { title: strings.screens.evaluation.quiz.sendModal.title, open: !!answers.data, onCancel: () => {
                    setAnswers({ data: undefined, loading: false });
                }, width: 572, footer: _jsx(FooterModal, { primary: {
                        loading: answers.loading,
                        action: sendAnswers,
                        customText: strings.screens.evaluation.quiz.actions.send.title,
                    }, secondary: {
                        action: () => {
                            setAnswers({ data: undefined, loading: false });
                        },
                    } }, 'footer_modal'), children: _jsx(Paragraph, { style: { marginTop: 8, marginBottom: 16, fontWeight: 400 }, children: showResults
                        ? strings.screens.evaluation.quiz.sendModal.descriptionwithResults
                        : strings.screens.evaluation.quiz.sendModal.descriptionwithoutResults }) }), showProgress && (_jsx(TuringoModal, { open: !!showProgress, onCancel: () => setShowProgress(undefined), width: 572, footer: [
                    _jsx(FooterModal, { primary: {
                            action: () => setShowProgress(undefined),
                            customText: 'OK',
                        } }, 'footer_modal'),
                ], children: _jsx(CourseProgress, { isEvaluation: true, progress: showProgress.sequence, title: strings.screens.boards.course.certificate.progress.title, description: strings.screens.boards.course.certificate.progress.description }) })), (!attempt || attempt <= 0) && !quiz.data && (_jsxs(_Fragment, { children: [_jsx("div", { style: {
                            background: 'var(--card-secondary-background)',
                            height: 198,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }, children: _jsxs("div", { style: {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }, children: [!isReleased && (_jsx(Alert, { style: { marginBottom: 16 }, showIcon: true, message: `${strings.general.availableOn} ${dayjs((_15 = (_14 = lesson.item.quiz) === null || _14 === void 0 ? void 0 : _14.options) === null || _15 === void 0 ? void 0 : _15.dateFrom)
                                        .locale(language)
                                        .format(strings.general.dateTimeFormat)}` })), !(((_17 = (_16 = lesson.item.quiz) === null || _16 === void 0 ? void 0 : _16.options) === null || _17 === void 0 ? void 0 : _17.repetitions) <= ((_19 = (_18 = lesson.item.quiz) === null || _18 === void 0 ? void 0 : _18.results) === null || _19 === void 0 ? void 0 : _19.repetition)) && !isApproved && (_jsx(TuringoButton, { disabled: !isReleased, onClick: retrieveQuiz, icon: _jsx(ViewList, {}), loading: quiz.loading, children: strings.screens.evaluation.action }))] }) }), _jsxs("div", { style: {
                            padding: '16px 24px',
                        }, children: [_jsx("div", { children: strings.screens.evaluation.title }), _jsx(Paragraph, { className: "turingo-title", style: { wordWrap: 'break-word' }, children: (_20 = lesson === null || lesson === void 0 ? void 0 : lesson.item) === null || _20 === void 0 ? void 0 : _20.title })] })] })), attempt > 0 && (_jsxs(Card, { style: { margin: '0px 24px 16px 24px' }, children: [_jsx(InformationModalBody, { forceLeftTextAlign: true, kind: !showResults || isApproved || !isEvaluation ? 'success' : 'error', title: getText().title, description: getText().description, subdescription: getText().subdescription }), _jsx(Row, { justify: 'end', children: getText().action.map((action, i) => {
                            return (_jsx(TuringoButton, { loading: quiz.loading, onClick: () => getAction(action), style: { marginRight: getText().action.length - 1 !== i ? 8 : 0 }, type: getText().action.length - 1 === i ? 'primary' : 'default', children: getActionText(action) }, i));
                        }) })] })), quiz.data && _jsx(QuizAnswerForm, { quiz: quiz.data, quizComplete: quizComplete, onSubmit: onSubmit }), quizComplete && showResults && (_jsx(Row, { className: "lateral-padding", justify: 'end', style: { marginBottom: 16 }, children: getText().action.map((action, i) => {
                    return (_jsx(TuringoButton, { loading: quiz.loading, onClick: () => getAction(action), type: "primary", children: getActionText(action) }, i));
                }) }))] }));
};
export { QuizResultsScreen };
