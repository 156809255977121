import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Skeleton, Tabs } from 'antd';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { useHistory, useParams } from 'react-router';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { Router } from '@Router';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TuringoList } from '@components/list/TuringoList';
import Paragraph from 'antd/lib/typography/Paragraph';
import useBreakpoints from '@hooks/useBreakpoints';
import { useAppDispatch } from '@hooks/useStore';
import strings from '@resources/localization';
import { postReactionList } from '@state/actions';
import { getMoniker } from './PostAuthor';
const { TabPane } = Tabs;
const ReactionModal = (props) => {
    const { cm_pk, b_pk: br_pk, p_pk } = useParams();
    const isFeed = !br_pk;
    const { target, open, onCancel } = props;
    const isMobile = useBreakpoints().isMobile;
    const [reactionState, setReactionState] = useState({
        data: [],
        loading: true,
        pagination: 'first',
    });
    const [kind, setKind] = useState(props.kind);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const afterAction = (result, page) => {
        if (page == 'first') {
            setReactionState({ data: result.data, loading: false, pagination: result.pagination });
        }
        else {
            setReactionState({ data: [...reactionState.data, ...result.data], loading: false, pagination: result.pagination });
        }
    };
    const fetchReactions = (page) => {
        setReactionState(Object.assign(Object.assign({}, reactionState), { loading: true }));
        void dispatch(postReactionList({
            urlParams: {
                cm_pk,
                b_pk: target.boardPk,
                target_pk: target.publicKey,
            },
            extraParams: {
                feed: isFeed,
            },
            headerParams: {
                page: page == 'first' ? null : page,
            },
            searchParams: {
                kind: kind,
            },
            options: {
                then: {
                    action: (result) => afterAction(result, page),
                },
            },
        }));
    };
    useEffect(() => {
        void fetchReactions('first');
    }, [kind]);
    const listComponent = (_jsx(InfiniteScroll, { scrollableTarget: 'current-modal', className: 'scrollStyle', height: isMobile ? 340 : 320, dataLength: reactionState.loading && reactionState.pagination == 'first' ? 0 : reactionState.data.length, next: () => {
            void fetchReactions(reactionState.pagination);
        }, hasMore: reactionState.pagination && reactionState.pagination != 'first', loader: _jsxs("div", { style: { padding: isMobile ? '0 16px' : '0 24px', marginTop: 24, display: 'grid', gridAutoFlow: 'row', gap: 24 }, children: [_jsx(Skeleton, { active: true, title: true, paragraph: null }), _jsx(Skeleton, { active: true, title: true, paragraph: null }), _jsx(Skeleton, { active: true, title: true, paragraph: null })] }, 'skeleton'), children: _jsx(TuringoList, { emptyText: " ", loading: reactionState.loading, showDivider: false, showArrow: false, itemRender: (item) => (_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(AvatarTuringo, { size: 24, style: {
                            height: 48,
                            width: 48,
                            lineHeight: '48px',
                            marginRight: 8,
                        }, name: item.name, avatar: item.avatarUrl }), _jsxs("div", { style: { lineHeight: '16px', display: 'flex', flexDirection: 'column', width: '100%' }, children: [_jsx("div", { className: "turingo-text", style: { display: 'flex', alignItems: 'center', fontWeight: 500 }, children: item.name }), getMoniker(item)] })] }, item.publicKey)), items: reactionState.data.map((item) => {
                return { item: item.author, publicKey: item.author.publicKey };
            }), onClick: (value) => history.push(Router.Path.user_details({ u_pk: value, cm_pk })) }) }));
    const getTitle = () => {
        if (props.kind == 'react') {
            return `${strings.general.like} (${target.totalReactions})`;
        }
        if (props.kind == 'vote') {
            return strings.general.votes.title;
        }
        else {
            return strings.screens.markasread.details.title;
        }
    };
    return (_jsxs(TuringoModal, { width: 500, open: open, onCancel: onCancel, footer: null, closable: true, title: null, bodyStyle: { padding: '0px 0px 16px 0px' }, children: [_jsx("div", { className: "turingo-card-header-padding", children: _jsx(Paragraph, { className: "turingo-title", strong: true, children: getTitle() }) }), (kind == 'react' || kind == 'vote') && _jsx("div", { style: { marginTop: 16 }, children: listComponent }), (kind == 'pin_read' || kind == 'pin_view') && (_jsxs(Tabs, { className: "turingo-card-tabs", onChange: (key) => setKind(key), children: [_jsx(TabPane, { tab: _jsxs("div", { style: {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }, children: [strings.screens.markasread.details.viewers.title, " ", _jsx("div", { className: 'tag', children: target.totalViews })] }), children: listComponent }, 'pin_view'), _jsx(TabPane, { tab: _jsxs("div", { style: {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }, children: [strings.screens.markasread.details.readers.title, " ", _jsx("div", { className: 'tag', children: target.totalReads })] }), children: listComponent }, 'pin_read')] }))] }));
};
export { ReactionModal };
