import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import { Affix, Layout } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { communityDetails } from '@state/actions';
import SVG from '@components/assets/SVG';
import ImageLogoBlack from '@bundle/assets/branding/logo-letterblack.svg';
import ImageLogoWhite from '@bundle/assets/branding/logo-letterwhite.svg';
import strings from '@resources/localization';
import { Banner } from '@components/navegation/banners/Banner';
import { Content } from 'antd/lib/layout/layout';
import { Router } from '@Router';
import { SidebarItems } from '@components/navegation/sider/Siderbar/SidebarItems';
import { isEntityLanding } from '@util/router/ReverseRouter';
import { turingoRoutes } from '@components/routing/auth/AuthRouting';
import { AdminSidebarItems } from '@components/navegation/sider/AdminSidebar/AdminSidebarItems';
import { hideSidebarRoutes } from '@Application';
import { Redirect, useLocation } from 'react-router';
const { Sider } = Layout;
const CommunityLayout = () => {
    var _a;
    const collapsed = useAppSelector((state) => state.ui.collapsed);
    const manual_collapsed = useAppSelector((state) => state.ui.manual_collapsed);
    const currentUser = useAppSelector((state) => state.auth.currentEntity);
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const isTablet = breakpoints.isTablet;
    const isSmall = breakpoints.isSmall;
    const theme = useAppSelector((state) => state.ui.theme);
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const communities = useAppSelector((state) => state.auth.communities);
    const isClassroomLanding = isEntityLanding('courses');
    const isForumLanding = isEntityLanding('talks');
    const community = useAppSelector((state) => state.community.items[currentCommunity]);
    const location = useLocation();
    const validation = useAppSelector((state) => state.auth.authValidation);
    const hideSidebar = hideSidebarRoutes(location.pathname, validation);
    const [firstLoad, setFirstLoad] = useState(true);
    useEffect(() => {
        const paths = location === null || location === void 0 ? void 0 : location.pathname.split('/');
        if (firstLoad) {
            if (paths.length >= 2 && !turingoRoutes.includes(paths[1])) {
                setFirstLoad(false);
                void dispatch(communityDetails({ urlParams: { cm_pk: paths[1] } }));
            }
            else {
                if (!community || !(community === null || community === void 0 ? void 0 : community.item)) {
                    setFirstLoad(false);
                    void dispatch(communityDetails({ urlParams: { cm_pk: 'turingo' } }));
                }
            }
        }
        else {
            if (currentCommunity != paths[1]) {
                if (paths.length >= 2 && !turingoRoutes.includes(paths[1])) {
                    setFirstLoad(false);
                    void dispatch(communityDetails({ urlParams: { cm_pk: paths[1] } }));
                }
                else {
                    if (!community || !(community === null || community === void 0 ? void 0 : community.item)) {
                        setFirstLoad(false);
                        void dispatch(communityDetails({
                            urlParams: { cm_pk: 'turingo' },
                        }));
                    }
                }
            }
        }
    }, [location, isLogged]);
    const noMarginTop = location.pathname == '/community' ||
        location.pathname == '/' ||
        location.pathname == '/saas' ||
        location.pathname == '/saas/form' ||
        (location.pathname == '/turingo/courses' && !isLogged) ||
        (location.pathname == '/turingo/talks' && !isLogged) ||
        (location.pathname == '/turingo/stories' && !isLogged) ||
        location.pathname.startsWith('/billing');
    const isAdminPanel = location.pathname.split('/')[2] == 'settings';
    const showCommunities = currentUser && communities && !communities.loading && ((_a = communities.items) === null || _a === void 0 ? void 0 : _a.length) > 1 && !isAdminPanel;
    if (isLogged && (community === null || community === void 0 ? void 0 : community.item) && !community.item.onboardingCompleted) {
        const paths = location === null || location === void 0 ? void 0 : location.pathname.split('/');
        if (paths[1] && paths[2] != 'onboarding' && paths[2] != 'join') {
            return _jsx(Redirect, { to: Router.Path.community_onboarding({ cm_pk: paths[1] }) });
        }
    }
    return (_jsxs(_Fragment, { children: [!isMobile && isLogged && !hideSidebar && (_jsx(Affix, { offsetTop: 48, style: { zIndex: 9 }, children: _jsx("div", { className: "turingo-sidebar", style: { display: 'inline-flex' }, children: _jsxs(Sider, { width: !showCommunities ? 269 : 206, style: {
                            overflow: 'auto',
                            height: 'calc(100vh - 48px)',
                        }, collapsedWidth: 56, trigger: null, collapsible: true, collapsed: (collapsed && breakpoints.lg) || (!isAdminPanel && manual_collapsed), children: [!isAdminPanel && _jsx(SidebarItems, { mode: 'inline', collapsed: (collapsed && breakpoints.lg) || manual_collapsed }), isAdminPanel && _jsx(AdminSidebarItems, { mode: 'inline', collapsed: collapsed && breakpoints.lg }), _jsx("div", { style: {
                                    padding: 16,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    textAlign: breakpoints.lg ? 'center' : 'start',
                                    alignItems: 'center',
                                }, children: !breakpoints.lg && !manual_collapsed && currentCommunity != 'turingo' && (_jsxs(_Fragment, { children: [_jsx("p", { style: { marginBottom: 0, marginRight: 8, fontSize: 12, color: 'var(--secondary-text)' }, children: strings.general.createdBy }), _jsx(SVG, { style: { width: '85.6px', height: '19.02px' }, content: theme == 'dark' ? ImageLogoWhite : ImageLogoBlack })] })) })] }) }) })), _jsxs(Layout, { id: "content", children: [isLogged && _jsx(Banner, { offsetTop: isMobile && (isClassroomLanding || isForumLanding) ? 116 : 48 }), _jsx(Content, { style: {
                            alignSelf: 'center',
                            width: '100%',
                            marginTop: noMarginTop ? 0 : isMobile ? 16 : 24,
                            marginBottom: noMarginTop ? 0 : isMobile ? 16 : 24,
                            paddingLeft: isSmall || noMarginTop ? 0 : isTablet ? 16 : 24,
                            paddingRight: isSmall || noMarginTop ? 0 : isTablet ? 16 : 24,
                        }, children: _jsx(Router.Component, {}) })] })] }));
};
export { CommunityLayout };
