var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { store } from '@state/persistance/Storage';
import { Route, Redirect, useHistory } from 'react-router';
import queryString from 'query-string';
import { Router } from '@Router';
import { createContext } from 'react';
import { RedirectComponent } from '@screens/auth/redirect/RedirectComponent';
const ANALYTICS = true;
export const turingoRoutes = ['billing', 'user', 'notifications', 'settings', 'terms', 'privacy', 'help', 'aboutUs', 'login', 'signup', 'debug'];
export const AnalyticsContext = createContext(null);
const AnalyticsRoute = (props) => {
    var _a;
    const actualProps = __rest(props, []);
    const queryParams = queryString.parse(props.location.search);
    const history = useHistory();
    if (queryParams === null || queryParams === void 0 ? void 0 : queryParams.utm_source) {
        history.replace({
            search: '',
        });
    }
    const paths = (_a = props.location) === null || _a === void 0 ? void 0 : _a.pathname.split('/');
    return (_jsx(AnalyticsContext.Provider, { value: { screen: props.name, route: props.location.pathname, communityPk: paths[1] }, children: _jsx(Route, Object.assign({}, actualProps)) }));
};
const RedirectSigned = (props) => {
    const { auth } = store === null || store === void 0 ? void 0 : store.getState();
    if (auth.authToken) {
        return _jsx(RedirectComponent, {});
    }
    else {
        const actualProps = __rest(props, []);
        return _jsx(AnalyticsRoute, Object.assign({ name: props.name }, actualProps));
    }
};
const RoutePrivate = (props) => {
    var _a, _b;
    const { auth, community } = store === null || store === void 0 ? void 0 : store.getState();
    if (auth.authToken) {
        const actualProps = __rest(props, []);
        return _jsx(AnalyticsRoute, Object.assign({ name: props.name }, actualProps));
    }
    else if (auth.authState == 'unauth.deleted') {
        return (_jsx(Redirect, { from: (_a = props.location) === null || _a === void 0 ? void 0 : _a.pathname, to: {
                pathname: Router.Path.community_exit({ cm_pk: community === null || community === void 0 ? void 0 : community.currentCommunity }),
                state: {
                    exit: true,
                },
            } }));
    }
    else {
        return (_jsx(Redirect, { from: (_b = props.location) === null || _b === void 0 ? void 0 : _b.pathname, to: {
                pathname: '/signup',
                state: Object.assign(Object.assign({}, props.location.state), { next: { pathname: location.pathname } }),
            } }));
    }
};
export { AnalyticsRoute, RedirectSigned, RoutePrivate };
