import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
export const CatalogueItem1 = (props) => {
    return (_jsx(Icon, Object.assign({ component: () => {
            if (props.filled) {
                return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: '100%', viewBox: "0 0 24 24", fill: 'currentColor', children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.5 3.5C3.5 2.1193 4.61925 1 6 1H18C19.3807 1 20.5 2.1193 20.5 3.5V20.5C20.5 21.8807 19.3807 23 18 23H6C4.61927 23 3.5 21.8807 3.5 20.5V3.5ZM6 3C5.72385 3 5.5 3.22385 5.5 3.5V20.5C5.5 20.7762 5.72383 21 6 21H18C18.2762 21 18.5 20.7762 18.5 20.5V3.5C18.5 3.22385 18.2762 3 18 3H6Z" }), _jsx("path", { d: "M9.5 9C9.5 7.61928 10.6193 6.5 12 6.5C13.3807 6.5 14.5 7.61928 14.5 9C14.5 10.3807 13.3807 11.5 12 11.5C10.6193 11.5 9.5 10.3807 9.5 9Z" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.5 3.5C5.5 3.22385 5.72385 3 6 3H18C18.2762 3 18.5 3.22385 18.5 3.5V20.5C18.5 20.7762 18.2762 21 18 21H6C5.72383 21 5.5 20.7762 5.5 20.5V3.5ZM7.5 9C7.5 6.51472 9.51472 4.5 12 4.5C14.4853 4.5 16.5 6.51472 16.5 9C16.5 11.4853 14.4853 13.5 12 13.5C9.51472 13.5 7.5 11.4853 7.5 9ZM10 17.5C9.44772 17.5 9 17.9477 9 18.5C9 19.0523 9.44772 19.5 10 19.5H14C14.5523 19.5 15 19.0523 15 18.5C15 17.9477 14.5523 17.5 14 17.5H10ZM8 14.5C7.44772 14.5 7 14.9477 7 15.5C7 16.0523 7.44772 16.5 8 16.5H16C16.5523 16.5 17 16.0523 17 15.5C17 14.9477 16.5523 14.5 16 14.5H8Z" })] }));
            }
            else {
                return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: '100%', viewBox: "0 0 24 24", fill: 'currentColor', children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.5 9C7.5 6.51472 9.51472 4.5 12 4.5C14.4853 4.5 16.5 6.51472 16.5 9C16.5 11.4853 14.4853 13.5 12 13.5C9.51472 13.5 7.5 11.4853 7.5 9ZM12 6.5C10.6193 6.5 9.5 7.61928 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61928 13.3807 6.5 12 6.5Z", fill: 'current' }), _jsx("path", { d: "M10 17.5C9.44772 17.5 9 17.9477 9 18.5C9 19.0523 9.44772 19.5 10 19.5H14C14.5523 19.5 15 19.0523 15 18.5C15 17.9477 14.5523 17.5 14 17.5H10Z", fill: 'current' }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.5 3.5C3.5 2.1193 4.61925 1 6 1H18C19.3807 1 20.5 2.1193 20.5 3.5V20.5C20.5 21.8807 19.3807 23 18 23H6C4.61927 23 3.5 21.8807 3.5 20.5V3.5ZM6 3C5.72385 3 5.5 3.22385 5.5 3.5V20.5C5.5 20.7762 5.72383 21 6 21H18C18.2762 21 18.5 20.7762 18.5 20.5V3.5C18.5 3.22385 18.2762 3 18 3H6Z", fill: 'current' }), _jsx("path", { d: "M8 14.5C7.44772 14.5 7 14.9477 7 15.5C7 16.0523 7.44772 16.5 8 16.5H16C16.5523 16.5 17 16.0523 17 15.5C17 14.9477 16.5523 14.5 16 14.5H8Z", fill: 'current' })] }));
            }
        } }, props)));
};
