import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingOutlined } from '@ant-design/icons';
import Style from './TuringoButton.scss';
import useBreakpoints from '@hooks/useBreakpoints';
import ClassNames from '@util/style/ClassNames';
import { Spin } from 'antd';
const TuringoButton = (props) => {
    const { onClick, icon, shape = 'standard', type = 'default', size = 'medium', children, expandInMobile, block, loading, disabled, style, suffixIcon } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsxs("div", { style: Object.assign(Object.assign({}, style), { width: (expandInMobile && isMobile) || block ? '100%' : 'fit-content', textAlign: 'center', flexDirection: shape == 'vertical' ? 'column' : 'row', opacity: loading ? 0.65 : 1 }), className: ClassNames(Style.button, disabled ? Style.disabled : Style.active, Style[shape], Style[size], Style[type], type != 'primary' && type != 'text' && !disabled && 'tgo-hover-button'), onClick: disabled || loading ? undefined : onClick, children: [loading && (_jsx("div", { className: "ant-btn-loading-icon", style: {
                    display: 'flex',
                    alignItems: 'center',
                    width: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                    minWidth: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                    height: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                    fontSize: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                    marginRight: children && shape != 'vertical' ? 4 : 0,
                    marginBottom: shape == 'vertical' ? 4 : 0,
                }, children: _jsx(Spin, { indicator: _jsx(LoadingOutlined, { style: { color: 'var(--neutral-6)', fontSize: isMobile ? 16 : 14, paddingRight: 4 }, spin: true }) }) })), icon && !loading && (_jsx("div", { style: {
                    display: 'flex',
                    alignItems: 'center',
                    width: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                    minWidth: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                    height: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                    fontSize: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                    marginRight: children && shape != 'vertical' ? 4 : 0,
                    marginBottom: shape == 'vertical' ? 4 : 0,
                }, children: icon })), _jsx("div", { className: "turingo-text", children: children }), suffixIcon && (_jsx("div", { style: {
                    display: 'flex',
                    alignItems: 'center',
                    width: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                    minWidth: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                    height: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                    fontSize: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                    marginLeft: children && shape != 'vertical' ? 4 : 0,
                    marginBottom: shape == 'vertical' ? 4 : 0,
                }, children: suffixIcon }))] }));
};
export { TuringoButton };
