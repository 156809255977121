import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { kindToLayout } from '@screens/posts/PostDetail';
import { PostContext } from '../../Post';
import { PostAuthor } from '../PostAuthor';
import { PostMenu } from '../PostMenu';
import { useAppSelector } from '@hooks/useStore';
import useBreakpoints from '@hooks/useBreakpoints';
import { TopicComponent } from '../../post_modal/topics/TopicComponent';
const PostHeader = (props) => {
    const { post } = useContext(PostContext);
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const layout = kindToLayout[post.kind];
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsxs("div", { style: { paddingTop: 16, paddingLeft: isMobile ? 16 : 24, paddingRight: isMobile ? 16 : 24 }, children: [layout.autor == 'header' && (_jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: 16, flexFlow: 'nowrap' }, children: [post.author && (_jsx(PostAuthor, { type: post.kind == 'rate' ? 'review' : 'post', author: post.author, date: post.dateCreated, postKey: post.publicKey, board: post.board })), isLogged && (_jsx("span", { children: _jsx(PostMenu, {}) }))] })), layout.topics == 'header' && post.topics && (_jsx(TopicComponent, { style: { marginBottom: 16, maxWidth: 'calc(100% - 48px)' }, maxDivSize: isMobile ? 300 : 500, postPk: post.publicKey, topics: post.topics }, post.topics.length))] }));
};
export { PostHeader };
