import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Meta from 'react-meta-tags';
import { Card, Form, Tooltip } from 'antd';
import strings from '@resources/localization';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Redirect, useParams } from 'react-router';
import Paragraph from 'antd/lib/typography/Paragraph';
import useBreakpoints from '@hooks/useBreakpoints';
import { FormNameItem } from '@components/form/FormNameItem/FormNameItem';
import { FooterModal } from '@components/modals/components/FooterModal';
import { communityMemberSchema, userEdit } from '@state/actions';
import { Router } from '@Router';
import dayjs from 'dayjs';
import localeES from 'antd/es/date-picker/locale/es_ES';
import localeEN from 'antd/es/date-picker/locale/en_US';
import { useRequestState } from '@api/requests/AsyncRequests';
import { addCategoryIdToProperties, getOrderedProperties, schemaOnboarding, } from '@screens/community/admin_panel/screens/profiles/Utils';
import { CustomFormItem } from '@components/content/entity/user_header/components/user_modal/CustomFormItem';
import { FormPhoneItem } from '@components/form/FormPhoneItem/FormPhoneItem';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SpinLoader } from '@components/content/spin_loader/SpinLoader';
require('dayjs/locale/es');
require('dayjs/locale/en');
const Onboarding = (props) => {
    var _a, _b, _c, _d;
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const community = useAppSelector((state) => { var _a; return (_a = state.community.items[currentCommunity]) === null || _a === void 0 ? void 0 : _a.item; });
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const [form] = Form.useForm();
    const language = useAppSelector((state) => state.ui.language);
    const { cm_pk } = useParams();
    dayjs.locale(language);
    const [welcome, setWelcome] = useState(false);
    const locale = language == 'es' ? localeES : localeEN;
    const [loadingOnboarding, setLoadingOnboarding] = useState(true);
    const dispatch = useAppDispatch();
    const [schema, setSchema] = useRequestState(undefined, true, true);
    const communityProperties = ((_a = schema === null || schema === void 0 ? void 0 : schema.data) === null || _a === void 0 ? void 0 : _a.categories) ? getOrderedProperties(addCategoryIdToProperties((_b = schema === null || schema === void 0 ? void 0 : schema.data) === null || _b === void 0 ? void 0 : _b.categories)) : [];
    const submit = (values) => {
        var _a;
        const { memberProperties } = values;
        for (const key in memberProperties) {
            communityProperties.map((property) => {
                if (property.id == key) {
                    if (property.type == 'geo:city:id') {
                        if (memberProperties[key]) {
                            memberProperties[key] = parseInt(memberProperties[key].value);
                        }
                    }
                    if (property.type == 'array:opt:single') {
                        if (typeof memberProperties[key] === 'string') {
                            memberProperties[key] = [memberProperties[key]];
                        }
                    }
                    if (property.type == 'integer') {
                        if (typeof memberProperties[key] === 'string') {
                            memberProperties[key] = parseInt(memberProperties[key]);
                        }
                    }
                    if (property.type == 'boolean') {
                        if (typeof memberProperties[key] === 'string') {
                            memberProperties[key] = memberProperties[key] == 'true';
                        }
                    }
                }
            });
        }
        void dispatch(userEdit({
            urlParams: { cm_pk: currentCommunity },
            bodyParams: {
                name: values.name,
                phoneNumber: ((_a = values.phone) === null || _a === void 0 ? void 0 : _a.length) > 0 ? values.phone : null,
                memberProperties: Object.assign({}, memberProperties),
            },
            options: {
                then: {
                    action: () => setWelcome(true),
                },
            },
        }));
    };
    useEffect(() => {
        void dispatch(communityMemberSchema({
            urlParams: { cm_pk },
            searchParams: { onboarding: true },
            options: { redux: { stateHandler: setSchema, retrieveData: true, }, then: { action: () => setLoadingOnboarding(false) } },
        }));
    }, []);
    const currentUser = useAppSelector((state) => state.auth.currentEntity);
    const loading = useAppSelector((state) => state.auth.loading);
    const orderedProperties = schemaOnboarding(schema.data);
    if (community && (community === null || community === void 0 ? void 0 : community.onboardingCompleted)) {
        const paths = location === null || location === void 0 ? void 0 : location.pathname.split('/');
        return _jsx(Redirect, { to: { pathname: Router.Path.community_home({ cm_pk: paths[1] }), state: { welcome: welcome } } });
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Meta, { children: [_jsx("title", { children: strings.screens.auth.signup.browserTitle }), _jsx("meta", { name: "description", content: strings.screens.auth.signup.browserDescription })] }), _jsxs("div", { style: {
                    display: 'flex',
                    justifyContent: 'center',
                    flexGrow: 1,
                    alignItems: 'center',
                    minHeight: '80vh',
                    paddingLeft: breakpoints.xxs ? 0 : 16,
                    paddingRight: breakpoints.xxs ? 0 : 16,
                }, children: [(schema.editing || loadingOnboarding) && _jsx(SpinLoader, { showIcon: true, loading: true }), schema.data && !schema.editing && !loadingOnboarding && (_jsx(Card, { style: { height: 'fit-content', minWidth: 'min(590px,100%)' }, children: _jsx("div", { style: { maxWidth: 500, marginLeft: 'auto', marginRight: 'auto' }, children: _jsxs("div", { style: { display: 'flex', justifyContent: 'center', flexDirection: 'column' }, children: [community && (_jsx(AvatarTuringo, { conteinerStyle: { display: 'flex', justifyContent: 'center' }, avatar: community.logoUrl, color: (_d = (_c = community.ui) === null || _c === void 0 ? void 0 : _c.style) === null || _d === void 0 ? void 0 : _d.color, size: 16, width: 56, height: 56, style: {
                                            border: ' 1px solid var(--hover)',
                                            lineHeight: '56px',
                                            cursor: 'pointer',
                                        }, name: community.name })), _jsx(Paragraph, { style: {
                                            textAlign: 'center',
                                            fontWeight: 500,
                                            fontSize: isMobile ? 24 : 30,
                                            lineHeight: isMobile ? '32px' : '38px',
                                            marginTop: 16,
                                            marginBottom: 16,
                                        }, children: strings.screens.admin.profiles.tabs.onboarding.subtitle }), _jsx("div", { style: { display: 'flex', justifyContent: 'center' }, children: _jsxs(Form, { initialValues: {
                                                name: currentUser.name,
                                            }, onFinish: submit, form: form, style: { maxWidth: isMobile ? '100%' : 400, minWidth: isMobile ? '100%' : 400, marginBottom: 40 }, labelCol: { span: 24 }, wrapperCol: { span: 24 }, layout: "vertical", children: [_jsx(FormNameItem, { name: 'name', label: strings.screens.profile.editModal.name.label, validations: ['required', 'format', 'long', 'short'] }), orderedProperties
                                                    .filter((value) => value.highlight)
                                                    .map((property) => {
                                                    return _jsx(CustomFormItem, { property: property }, property.id);
                                                }), orderedProperties
                                                    .filter((value) => !value.highlight)
                                                    .map((property) => {
                                                    return _jsx(CustomFormItem, { property: property }, property.id);
                                                }), _jsx(FormPhoneItem, { label: _jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(Paragraph, { children: strings.screens.settings.sections.signup.changePhone.title }), _jsx(Tooltip, { placement: "right", title: strings.screens.settings.sections.signup.changePhone.help, children: _jsx(InfoCircleOutlined, { onClick: (event) => event.stopPropagation(), style: { fontSize: breakpoints.isMobile ? 16 : 14, marginLeft: 4 } }) })] }), name: ['phone'] })] }) }), _jsx(FooterModal, { primary: {
                                            loading: loading,
                                            action: form.submit,
                                            customText: strings.general.next,
                                        } }, 'footer_modal')] }) }) }))] })] }));
};
export { Onboarding };
