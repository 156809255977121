import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LikeIcon } from '@components/icons/LikeIcon';
import Paragraph from 'antd/lib/typography/Paragraph';
export const PostReactionsTag = (props) => {
    if (props.totalReactions == 0)
        return _jsx("span", {});
    return (_jsxs("div", { onClick: props.onClick, style: {
            display: 'flex',
            cursor: 'pointer',
            padding: '4px 8px',
            background: 'var(--ant-primary-1)',
            border: '1px solid var(--ant-primary-color)',
            borderRadius: '20px',
            alignItems: 'center',
            color: "var(--ant-primary-color)"
        }, children: [_jsx(LikeIcon, { style: { fontSize: 16, height: 16, width: 16, marginRight: 4, display: 'flex' } }), _jsxs(Paragraph, { className: 'turingo-text', style: { color: 'var(--ant-primary-color)' }, children: [" ", props.totalReactions] })] }));
};
