import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { use100vh } from 'react-div-100vh';
import { isSafari } from 'react-device-detect';
import { Button, Typography } from 'antd';
import { Colors } from '@util/color/Colors';
import styles from './TuringoModal.scss';
import useBreakpoints from '@hooks/useBreakpoints';
import { Close } from '@icon-park/react';
import { createPortal } from 'react-dom';
const TuringoModal = (props) => {
    const { scrolleable = true, padding, width, bottomMargin = 0, bodyStyle, open, hidden, customTitle, confirmLoading, onCancel, closable = true, footer, children, fullScreenMobile, title, blockHorizontalScroll = false, } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const height = use100vh();
    const modalContainer = {
        visibility: hidden ? 'hidden' : 'visible',
        display: 'flex',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1001,
        height: '100dvh',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,.65)',
        alignItems: 'center',
        padding: padding ? padding : 16,
    };
    const fullScreenContainer = {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: isSafari ? 'calc(1rem + env(safe-area-inset-bottom))' : 0,
        zIndex: 1001,
        height: isSafari ? `calc(${height}px - ${bottomMargin}px)` : '100dvh',
    };
    const modal = Object.assign({ position: 'relative', height: 'fit-content', maxHeight: 'calc(100dvh - 2rem)', maxWidth: width, minWidth: `min(${width}px, 100%)`, verticalAlign: 'middle', backgroundColor: Colors('BACKGROUND'), borderRadius: 4, padding: padding ? padding : isMobile ? '16px 14px' : '16px 22px', display: 'flex', flexDirection: 'column' }, (bodyStyle && Object.assign({}, bodyStyle)));
    const fullScreen = {
        position: 'fixed',
        overflow: 'hidden',
        backgroundColor: Colors('BACKGROUND'),
        zIndex: 1001,
        left: 0,
        right: 0,
        bottom: 0,
        height: isSafari ? `calc(${height}px - ${bottomMargin}px)` : '100%',
        width: '100%',
        padding: padding ? padding : 16,
        display: 'flex',
        flexDirection: 'column',
    };
    if (open) {
        return createPortal(_jsx("div", { className: isMobile && fullScreenMobile ? '' : styles.animationDiv, style: isMobile && fullScreenMobile ? fullScreenContainer : modalContainer, children: _jsxs("div", { className: isMobile && fullScreenMobile ? '' : styles.animationModal, style: isMobile && fullScreenMobile ? fullScreen : modal, children: [closable && (_jsx(Button, { className: 'turingo-small-circle-btn', onClick: onCancel, type: "text", icon: _jsx(Close, { style: {
                                color: 'var(--neutral-7)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: isMobile ? 16 : 14,
                            } }), style: { position: 'absolute', zIndex: 100, right: 16, top: 8 } })), title && (_jsx(Typography.Paragraph, { className: "turingo-title", style: { paddingBottom: 16, paddingRight: 24, zIndex: 1 }, children: title })), customTitle && _jsx("div", { style: { paddingBottom: 16, paddingRight: 24, zIndex: 1 }, children: customTitle }), _jsx("div", { id: "current-modal", className: "scrollStyle", style: Object.assign(Object.assign(Object.assign({}, (scrolleable && { maxHeight: isMobile ? '90vh' : '72vh', overflowY: 'auto' })), (fullScreenMobile && scrolleable && isMobile && { height: '90vh' })), (blockHorizontalScroll && { overflowX: 'hidden' })), children: children }), footer && _jsx("div", { style: { paddingTop: 16 }, children: footer })] }) }), document.body);
    }
    else
        return _jsx(_Fragment, {});
};
export { TuringoModal };
