import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InfoCircleFilled } from '@ant-design/icons';
import strings from '@resources/localization';
import { Router } from '@Router';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { AlertActions } from '@components/alerts/alert_actions/AlertActions';
import { useAppSelector } from '@hooks/useStore';
import useBreakpoints from '@hooks/useBreakpoints';
import { useHistory, useParams } from 'react-router';
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton';
const CompleteProfile = (props) => {
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const { cm_pk } = useParams();
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const history = useHistory();
    return (_jsx(AlertActions, { style: { marginBottom: isMobile ? 16 : 0 }, message: _jsxs("div", { style: { display: 'grid', gridAutoFlow: 'column', alignItems: 'center', gap: 12, marginBottom: isMobile ? 16 : 0 }, children: [_jsx(InfoCircleFilled, { style: { color: '#1890FF' } }), _jsx(AvatarTuringo, { name: currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.name, avatar: currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.avatarUrl, size: 13, width: 32, height: 32 }), _jsx("p", { style: { marginBottom: 0, lineHeight: '22px' }, children: strings.screens.boards.discussion.details.complete_profile.title })] }), onOk: _jsx(TuringoButton, { onClick: () => history.push(Router.Path.user_details({ u_pk: currentEntity.publicKey, cm_pk })), expandInMobile: true, type: "default", children: strings.screens.boards.discussion.details.complete_profile.action }) }));
};
export { CompleteProfile };
